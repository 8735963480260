<template>
  <div class="user-body">
    <div class="overlay">
      <app-header></app-header>
      <router-view />
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/layout/user/header.vue';
import AppFooter from '@/layout/user/footer.vue';
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  created() {
   
  },
};
</script>

<style>
.user-body {
  background-color: #0a8072;
  background-image: url(../../assets/2.jpg);
  height: 100vh;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  /* footer */
  display: flex;
  flex-direction: column;
}
</style>
