<template>
  <Menubar :model="items" class="user-Menubar">
    <template #start>
      <img
        alt="logo"
        v-if="image"
        :src="$baseUploadURL + image"
        height="40"
        class="p-mr-2"
      />
      <img
        alt="logo"
        v-else
        src="../../assets/logo.png"
        height="40"
        class="p-mr-2"
      />
    </template>
    <template #end>
      <Button
        label="تسجل خروج"
        @click="logout"
        icon="pi pi-power-off"
        class="p-button-danger"
      />
    </template>
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar';
export default {
  components: {
    Menubar,
  },
  name: 'app-header',
  data() {
    return {
      items: [
        {
          label: 'الرئيسيه',
          icon: 'pi pi-fw pi-home',
          to: '/user',
        },
      ],

      image: null,
    };
  },
  methods: {
    logout() {
      delete localStorage.user;
      location.reload();
    },
  },
  created() {
    this.$eventHub.$on('image', (val) => {
      this.image = val;
    });
    if (localStorage.logoImage){
       this.image = localStorage.logoImage;
    }
  },
};
</script>
<style>
.user-Menubar {
  padding-right: 5%;
  padding-left: 5%;
  background-color: #0a8072;
  border-color: #0a8072;
}

.user-Menubar .p-menuitem-link .p-menuitem-text {
  color: #e9ecef !important;
}

.user-Menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-icon {
  color: #e9ecef !important;
}

.user-Menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: #495057;
}
.user-Menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #e9ecef;
}
.user-Menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #e9ecef;
}
</style>
